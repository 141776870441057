import ajax from "superagent";
import { Album, EventType } from "../components";

export const fetchEventList = async (page: number, setEvents: Function) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=root&page=${page}`,
      {
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache"
        },
        cache: "no-store"
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    const meta = data.meta;
    setEvents(data.entities, meta.nextPage);
  } catch (error) {
    console.error("Failed to fetch events:", error);
  }
};

export const fetchAlbumDetails = async (eventId: number) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=Gallery&id=${eventId}`,
      {
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache"
        },
        cache: "no-store"
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.entities;
  } catch (error) {
    console.error(`Failed to fetch albums for event ${eventId}:`, error);
    return [];
  }
};

export const fetchAlbumPhotos = async (albumId: number, page: number) => {
  try {
    const response = await fetch(
      `/api/v1/photo-manager/contents?t=Album&id=${albumId}&page=${page}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch photos for album ${albumId}:`, error);
    return [];
  }
};

export const fetchEventsByName = async (name: string) => {
  try {
    const response = await fetch(`/api/v1/photo-manager/search?q=${name}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch events with the name ${name}:`, error);
    return [];
  }
};

export const handleModalSubmit = async (
  albumName: string,
  groupPhotos: boolean,
  selectedEvent: EventType,
  groupPhotosEnabled: boolean,
  setShowModal: Function,
  setAlbumsData: Function,
  albumsData: Album[],
  onCreateSuccess?: (newAlbum: Album) => void
) => {
  try {
    const response = await ajax.post(
      `/api/v1/albums?event_id=${selectedEvent.id}&name=${albumName}${
        groupPhotosEnabled ? `&group_photos=${groupPhotos}` : ""
      }`
    );

    if (!response.ok) {
      throw new Error("Failed to create album");
    }

    const newAlbum = response.body.album;
    setAlbumsData([...albumsData, newAlbum]);
    if (onCreateSuccess) {
      onCreateSuccess(newAlbum);
    }
    setShowModal(false);
  } catch (error) {
    console.error("Error creating album:", error);
    // @ts-ignore
    toastr.error("Unable to create album. Please try again", null, 1000);
  }
};

export const submitAlbumName = (
  e: any,
  albumId: number,
  albumName: string,
  hasInvalidatedValues: Function,
  setEditAlbumNames: Function,
  setEditNameSection: Function,
  setAlbumsData: Function,
  albumsData: Album[]
) => {
  e.stopPropagation();
  if (hasInvalidatedValues(albumId, true)) {
    return;
  }

  fetch(`/api/v1/album/${albumId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ name: albumName })
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 404) {
        console.error("Album not found.");
        throw new Error("Album not found.");
      } else {
        console.error("Failed to update album name.");
        throw new Error("Failed to update album name.");
      }
    })
    .then(updatedAlbum => {
      setEditAlbumNames((prev: any) => {
        const updatedNames = { ...prev };
        delete updatedNames[albumId];
        return updatedNames;
      });

      setEditNameSection((prev: any[]) =>
        prev.filter((id: number) => id !== albumId)
      );

      const copyOfAlbums = [...albumsData];
      const index = copyOfAlbums.findIndex(i => i.id == albumId);
      copyOfAlbums[index].name = updatedAlbum.album.name;
      setAlbumsData(copyOfAlbums);
    })
    .catch(error => {
      console.error("Error while updating album name:", error);
    });
};

export const handleDelete = async (
  setLoading: Function,
  albumId: number,
  onDeleteSuccess: Function,
  onClose: Function
) => {
  setLoading(true);
  try {
    const response = await ajax.delete(`/api/v1/albums/${albumId}`);
    if (!response.ok) {
      throw new Error("Failed to delete album");
    }
    onDeleteSuccess();
    onClose();
  } catch (error) {
    console.error("Error deleting album:", error);
    // @ts-ignore
    toastr.error("Unable to delete album. Please try again", null, 1000);
  } finally {
    setLoading(false);
  }
};
